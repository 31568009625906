import { FaIcon } from 'atomic'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'
import SvgIcWhatsapp from 'atomic/atm.svg-icon/ic-whatsapp'
import * as React from 'react'
import {
  CosmicjsInformacoesEstaticas,
  CosmicjsInstitucional
} from 'site/src/data/graphql/graphql-types'
import { FooterContent } from 'utils/model/footer-content'
import { getTelLink, removeTelAreaCode } from 'utils/number'
import { appPaths, getPathUrl } from 'utils/path'
import { getDetailPageUrl } from 'utils/url'
import { insertOtherLinksOnAbout } from '../footer.utils'

export const facebookUrl = 'https://www.facebook.com/ClinicaFelippeMattoso/'
export const instagramUrl = 'https://www.instagram.com/clinicafelippemattoso/'

export const mapToFooterContent = (data, isDoctor: boolean) => {
  const mapToLinkList = (doctor: boolean) => (item: CosmicjsInstitucional) => ({
    title: item.title,
    url: getDetailPageUrl(getPathUrl(appPaths.institutional.path, doctor), item.slug)
  })

  // const firstColLinks = data.allCosmicjsInstitucional.nodes.map(mapToLinkList(isDoctor))
  const firstColLinks = [
    {
      title: 'Sobre',
      url: getDetailPageUrl(getPathUrl(appPaths.institutional.path, isDoctor), 'sobre')
    },
    {
      url: `${getPathUrl(appPaths.news.path, isDoctor)}/conheca-o-atendimento-domiciliar`,
      title: 'Atendimento Domiciliar'
    },
    {
      title: 'Código de Confiança',
      url: getDetailPageUrl(
        getPathUrl(appPaths.institutional.path, isDoctor),
        'codigo-de-confianca'
      )
    },
    {
      title: 'Direitos e Deveres dos pacientes',
      url: getDetailPageUrl(
        getPathUrl(appPaths.institutional.path, isDoctor),
        'direitos-dos-pacientes'
      )
    }
  ]
  insertOtherLinksOnAbout(firstColLinks, isDoctor)

  const appLinks = (data.appLinks as CosmicjsInformacoesEstaticas).metadata
  const androidUrl: string = appLinks.playStoreLink
  const iosUrl: string = appLinks.appStoreLink

  const contact = (data.contact as CosmicjsInformacoesEstaticas).metadata

  const content: FooterContent = {
    section1: {
      title: 'Clínica Felippe Mattoso',
      links: firstColLinks
    },
    section3: {
      title: 'Parceiro e investidores',
      links: [
        {
          url: 'http://ri.fleury.com.br',
          title: 'Investidores',
          target: '_blank'
        }
        // {
        //   url: 'http://suprimentos.fleury.com.br/Paginas/default.aspx',
        //   title: 'Seja um Fornecedor',
        //   target: '_blank'
        // },
      ]
    },
    sectionContact: {
      title: 'Canais de contato',
      links: [
        {
          title: 'Whatsapp',
          icon: <SvgIcWhatsapp width={20} />,
          target: '_blank',
          url: contact.agendamentoWhatsappLink
        },
        {
          //title: removeTelAreaCode(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato),
          title: isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato,
          icon: <SvgIcTelefone width={20} />,
          target: '_blank',
          url: getTelLink(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato)
        }
      ]
    },
    sectionMedia: {
      title: 'Mídias sociais',
      links: [
        {
          title: 'Facebook',
          icon: <FaIcon.Facebook size="lg" />,
          target: '_blank',
          url: facebookUrl
        },
        {
          title: 'Instagram',
          icon: <FaIcon.Instagram size="lg" />,
          target: '_blank',
          url: instagramUrl
        }
      ]
    },
    sectionApps: {
      target: '_blank',
      androidUrl,
      iosUrl,
      title: 'Aplicativo'
    }
  }

  return content
}

import { Color, Spacing } from 'atomic'
import styled from 'styled-components'

export const SchedulingItemWrapper = styled.div`
  line-height: 25px;
  font-size: 12px !important;
`
export const SchedulingIconWrapper = styled.div`
  margin-left: 3px;
  margin-right: ${Spacing.Medium};
`

export const Subtitle = styled.p`
  line-height: 14px;
  font-size: 10px !important;
  color: ${Color.GrayXDark};
`

export const SchedulingModalStyled = styled.div`
  margin: 0 ${Spacing.Medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${Color.GrayXDark};
    font-weight: 600;
    margin-left: 25%;
  }

  h3 {
    font-weight: 400;
  }

  h4 {
    color: ${Color.GrayXDark};
  }
`

export const SecondModalStyled = styled.div`
  margin: 0 ${Spacing.Medium};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    color: ${Color.GrayXDark};
    font-weight: 600;
  }

  h3 {
    font-weight: 400;
    font-size: 13px !important;
  }

  h4 {
    color: ${Color.GrayXDark};
  }
`

export const WrapperFleuryModal = styled.div`
  width: 250px;
  justify-content: flex-start !important;

  button {
    text-align: left;
  }

  span {
    margin-left: 30px;
  }

  /* @media (max-width: 320px) {
		padding: 5px 0px;
	} */
`

export const AttendanceHours = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -2px;
  height: unset;
  background: #e5f3f1;
  width: 100%;
  align-items: start;
  border-radius: 8px;
  line-height: normal;
  padding: 15px 15px 0px 15px;
  font-size: 12px !important;

  p {
    font-size: 12px !important;
  }

  ul {
    padding-left: 16px;
  }
  
  li {
    margin-left: 0;
    font-size: 12px !important;
  }
`

export const Wrapper = styled.div`  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  padding-top: 10px;

  /* @media (max-width: 320px) {
		padding: 5px 0px;
	} */
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  width: 112px;
  height: 112px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(131, 127, 136, 0.2);
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #e1e1e1;
  }

  @media (max-width: 500px) {
    width: 112px;
  }
`

export const ObsWhatsapp = styled.div`
  p {
    font-size: 13px;
    margin-left: 50px;
    margin-top: -15px;
  }
`
export const HorarioAtendimento = styled.div`
  p {
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
  }
`

export const RowWhatsapp = styled.div`
  display: flex;
  align-items: baseline;
  font-family: 'Signika';
  .modal-agendar-axames {
    margin-left: 15px;
  }
`

export const LineSeparator = styled.div`
  border: solid 1px;
  border-color: #D0D0D0;
  margin: 24px 0px 12px 0px ;
  width: 100%;  
`
export const SchedulingButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  width: 220px;
  height: fit-content;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;  
`

export const SchedulingLinkButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  width: 220px;
  height: fit-content;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;  
`

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px 12px;
  width: 220px;
  height: fit-content;
  border-radius: 8px;
  text-align: center;
  margin-top: 12px;
  background-color: #ffffff;
`

export const ContactText = styled.span`
  font-family: 'ASAP', Verdana !important;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  color: #D31B50;  
`
export const ContactTextCustom = styled.span`
  font-family: 'ASAP', Verdana !important;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 12px;
  letter-spacing: normal;
  color: #FFFFFF;  
`
export const ContactLabel = styled.span`
  font-family: 'ASAP', Verdana !important;
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 10px;
  letter-spacing: normal;
  color: #464646;
  margin: 8px;
`
export const ContactIcon = styled.div`
  margin-left: 4px;
`
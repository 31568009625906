import { Button, ButtonNew, Col, ColNew, Color, H4, H4w, Hbox, Row, RowNew, RowWeinmann, Separator, SeparatorMediaAndContact } from 'atomic'

import SvgDownloadOnAppStore from 'atomic/atm.svg-icon/ic-download-on-app-store.component'
import SvgDownloadOnGooglePlay from 'atomic/atm.svg-icon/ic-download-on-google-play.component'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import * as React from 'react'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { FooterContent } from 'utils/model/footer-content'
import { isExternalUrl } from 'utils/url'
import { IconLinkCell } from '../../atm.icon-link-cell/icon-link-cell.component'
import {
  FooterAppAlignStyled,
  FooterDownloadButtonWrapper,
  FooterHorizontallDivisorStyled,
  FooterLinkWrapper,
  FooterLinkWrapperWeinmann,
  FooterVerticalDivisorStyled
} from '../footer.component.style'
import { useFooterContent } from './footer-content.utils'

interface FooterContentRowProps {
  isDoctor: boolean
}

export const FooterContentRow: React.FunctionComponent<FooterContentRowProps> = props => {
  const content: FooterContent = useFooterContent(props.isDoctor)
  return (
    <Row id="container-footer" mb>
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
        <>
          <Col xs={12} sm={9} md={10}>
            <Row>
              <Col xs={6} sm={6} md={7} lg={7}>
                <LinksRow {...content} />
                <Separator />
              </Col>
              <Col xs={6} sm={6} md={5} lg={5}>
                <ContactsAndMediaRow {...content} />
                <Separator />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={3} md={2}>
            <DownloadButtonSection {...content} />
          </Col>
        </>
      )}
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
        <>
          <Col xs={12} sm={8} md={10}>
            <Row>
              <Col xs={6} sm={6} md={7} lg={7}>
                <LinksRow {...content} />
                <Separator />
              </Col>
              <Col xs={6} sm={6} md={5} lg={5}>
                <ContactsAndMediaRow {...content} />
                <Separator />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4} md={2}>
            <DownloadButtonSection {...content} />
          </Col>
        </>
      )}
    </Row>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const LinksRow: React.FunctionComponent<FooterContent> = React.memo(
  props => {
    const columns = {
      md1: 5,
      md2: 3,
      md3: 4,
      lg: 4
    }
    if (!props.section2) {
      columns.md1 = 6
      columns.md3 = 6
      columns.lg = 6
    }

    return (
      <Row>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <Col xs={12} sm={6} md={columns.md1} lg={columns.lg}>
              <TitleRow {...props.section1} />
              {props.section1.links.map((link, index) => (
                <FooterLink id={`footer-link-section1-${index}`} {...link} key={link.title} />
              ))}
              <Separator />
            </Col>
            {props.section2 && (
              <Col xs={12} sm={6} md={columns.md2} lg={columns.lg}>
                <TitleRow title={props.section2 && props.section2.title} />
                {props.section2 &&
                  props.section2.links.map((link, index) => (
                    <FooterLink id={`footer-link-section2-${index}`} {...link} key={link.title} />
                  ))}
                <Separator />
              </Col>
            )}
            <Col xs={12} sm={6} md={columns.md3} lg={columns.lg}>
              <TitleRow title={props.section3 && props.section3.title} />
              {props.section3.links.map((link, index) => (
                <FooterLink
                  id={`footer-link-section3-${index}`}
                  {...link}
                  key={link.title}
                  target={link.target ? link.target : '_blank'}
                />
              ))}
              {/* <FooterLink
            id={`footer-link-section3-privacy`}
            url="/portal-de-privacidade"
            title="Portal de Privacidade"
            /> */}
              <FooterLink
                id={`footer-link-section3-privacy`}
                url="https://privacyportal.onetrust.com/policies/26edb1e8-78ab-465b-9129-d0d9cf1f833e?context=eyJkc1BvcnRhbElkIjoiMGFjZGE4ODgtNDNlZS00OTdjLTk4MmQtMTExMmE2Y2ZhM2NkIn0%3D"
                title="Portal de Privacidade"
                target="_blank"
              />
              <Separator />
            </Col>
          </>
        )}
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <>
            <Col xs={12} sm={12} md={columns.md1} lg={columns.lg}>
              <TitleRow {...props.section1} />
              {props.section1.links.map((link, index) => (
                <FooterLink
                  id={`footer-link-section1-${index}`}
                  {...link}
                  key={link.title}
                  target={link.target ? link.target : '_blank'}
                />
              ))}
              {/* <FooterLink
            id={`footer-link-section3-privacy`}
            url="/portal-de-privacidade"
            title="Portal de Privacidade"
            /> */}
              <FooterLink
                id={`footer-link-section3-privacy`}
                url="https://privacyportal.onetrust.com/policies/26edb1e8-78ab-465b-9129-d0d9cf1f833e?context=eyJkc1BvcnRhbElkIjoiMGFjZGE4ODgtNDNlZS00OTdjLTk4MmQtMTExMmE2Y2ZhM2NkIn0%3D"
                title="Portal de Privacidade"
                target="_blank"
              />
              <Separator />
            </Col>
            {props.section2 && (
              <Col xs={12} sm={12} md={columns.md2} lg={columns.lg}>
                <TitleRow title={props.section2 && props.section2.title} />
                {props.section2 &&
                  props.section2.links.map((link, index) => (
                    <FooterLink id={`footer-link-section2-${index}`} {...link} key={link.title} />
                  ))}
                <Separator />
              </Col>
            )}
            <Col xs={12} sm={12} md={columns.md3} lg={columns.lg}>
              <TitleRow title={props.section3 && props.section3.title} />
              {props.section3.links.map((link, index) => (
                <FooterLink
                  id={`footer-link-section3-${index}`}
                  {...link}
                  key={link.title}
                  target={link.target ? link.target : '_blank'}
                />
              ))}
              <Separator />
            </Col>
          </>
        )}
      </Row>
    )
  },
  () => true
)

////////////////////////////////////////////////////////////////////////////////////////////////////

const ContactsAndMediaRow: React.FunctionComponent<FooterContent> = React.memo(
  props => (
    <Row>
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
        <>
          <Col xs={12} sm={6} md={6} lg={6}>
            <TitleRow {...props.sectionMedia} />
            {props.sectionMedia.links
              .filter(link => link.url)
              .map((link, index) => (
                <React.Fragment key={link.title}>
                  <IconLinkCell
                    id={`icon-link-contact-${index}`}
                    light
                    icon={link.icon}
                    text={link.title}
                    to={link.url}
                    external={isExternalUrl(link.url)}
                    target="_blank"
                  />
                  <SeparatorMediaAndContact />
                </React.Fragment>
              ))}
          </Col>

          <Col xs={12} sm={6} md={6} lg={6}>
            <TitleRow {...props.sectionContact} />
            {props.sectionContact.links
              .filter(link => link.url)
              .map((link, index) => (
                <React.Fragment key={link.title}>
                  <IconLinkCell
                    id={`icon-link-contact-${index}`}
                    light
                    icon={link.icon}
                    text={link.title}
                    to={link.url}
                    external={isExternalUrl(link.url)}
                    target="_blank"
                  />
                  <SeparatorMediaAndContact />
                </React.Fragment>
              ))}
          </Col>
        </>
      )}
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
        <>
          <Col xs={12} sm={12} md={6} lg={6}>
            <TitleRow {...props.sectionContact} />
            {props.sectionContact.links
              .filter(link => link.url)
              .map((link, index) => (
                <React.Fragment key={link.title}>
                  <IconLinkCell
                    id={`icon-link-contact-${index}`}
                    light
                    icon={link.icon}
                    text={link.title}
                    to={link.url}
                    external={isExternalUrl(link.url)}
                    target="_blank"
                  />
                  <Separator />
                </React.Fragment>
              ))}
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <TitleRow {...props.sectionMedia} />
            {props.sectionMedia.links
              .filter(link => link.url)
              .map((link, index) => (
                <React.Fragment key={link.title}>
                  <IconLinkCell
                    id={`icon-link-contact-${index}`}
                    light
                    icon={link.icon}
                    text={link.title}
                    to={link.url}
                    external={isExternalUrl(link.url)}
                    target="_blank"
                  />
                  <Separator />
                </React.Fragment>
              ))}
          </Col>
        </>
      )}
    </Row>
  ),
  () => true
)

////////////////////////////////////////////////////////////////////////////////////////////////////

export const DownloadButtonSection: React.FunctionComponent<FooterContent> = React.memo(
  props => {
    const themeContext = useContext(ThemeContext)
    return (
      <FooterDownloadButtonWrapper>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <RowWeinmann mb>
              <Col xs={12} smOffset={2}>
                <Hbox>
                  <Hbox.Item>
                    <MatchMedia defaultMinWidth={`48em`}>
                      {md => <> {md && <FooterHorizontallDivisorStyled />}</>}
                    </MatchMedia>

                    <FooterAppAlignStyled>
                      <TitleRow {...props.sectionApps} />
                    </FooterAppAlignStyled>
                  </Hbox.Item>
                </Hbox>
              </Col>
            </RowWeinmann>
          </>
        )}
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <>
            <MatchMedia defaultMinWidth={`48em`}>
              {md => <>{!md && <FooterVerticalDivisorStyled color={Color.White} />}</>}
            </MatchMedia>

            <Row mb>
              <Col xs={12} smOffset={2}>
                <Hbox>
                  <Hbox.Item>
                    <MatchMedia defaultMinWidth={`48em`}>
                      {md => <>{md && <Separator />}</>}
                    </MatchMedia>
                    <TitleRow {...props.sectionApps} />
                  </Hbox.Item>
                </Hbox>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs={12} smOffset={2}>
            {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
              <>
                <RowNew>
                  <ColNew xs={6} sm={8}>
                    <a
                      id="button-store-ios"
                      href={props.sectionApps.iosUrl}
                      role="button"
                      target="_blank"
                    >
                      <SvgDownloadOnAppStore fill={themeContext.color.footer} />
                    </a>
                  </ColNew>
                  <ColNew xs={6} sm={8}>
                    <a
                      id="button-store-android"
                      href={props.sectionApps.androidUrl}
                      role="button"
                      target="_blank"
                    >
                      <SvgDownloadOnGooglePlay fill={themeContext.color.footer} />
                    </a>
                  </ColNew>
                </RowNew>
              </>
            )}

            {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
              <>
                <Row>
                  <Col xs={4} sm={8}>
                    <a
                      id="button-store-ios"
                      href={props.sectionApps.iosUrl}
                      role="button"
                      target="_blank"
                    >
                      <SvgDownloadOnAppStore fill={themeContext.color.footer} />
                    </a>
                  </Col>
                  <Col xs={4} sm={8}>
                    <a
                      id="button-store-android"
                      href={props.sectionApps.androidUrl}
                      role="button"
                      target="_blank"
                    >
                      <SvgDownloadOnGooglePlay fill={themeContext.color.footer} />
                    </a>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <MatchMedia defaultMinWidth={`48em`}>
              {md => <> {md && <FooterHorizontallDivisorStyled />}</>}
            </MatchMedia>
          </>
        )}
      </FooterDownloadButtonWrapper>
    )
  },
  () => true
)

////////////////////////////////////////////////////////////////////////////////////////////////////

interface FooterLinkProps {
  title: string
  url: string
  id: string
  target?: '_self' | '_parent' | '_blank' | '_top'
}

export const FooterLink: React.FunctionComponent<FooterLinkProps> = React.memo(
  ({ id, ...props }) => {
    return (
      <>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <FooterLinkWrapperWeinmann>
              <ButtonNew
                id={id}
                kind="link"
                to={props.url}
                light
                external={isExternalUrl(props.url)}
                target={props.target}
              >
                {props.title}
              </ButtonNew>
            </FooterLinkWrapperWeinmann>
          </>
        )}
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <>
            <FooterLinkWrapper>
              <Button
                id={id}
                kind="link"
                to={props.url}
                light
                external={isExternalUrl(props.url)}
                target={props.target}
              >
                {props.title}
              </Button>
            </FooterLinkWrapper>
          </>
        )}
      </>
    )
  }
)

////////////////////////////////////////////////////////////////////////////////////////////////////

interface TitleRowProps {
  title?: string
}

const TitleRow: React.FunctionComponent<TitleRowProps> = props => {
  return (
    <Row>
      <Col xs>
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() === 'weinmann' && (
          <>
            <H4w kind="light" bottomLine={props.title && props.title.length > 0}>
              {props.title}
            </H4w>
          </>
        )}
        {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' && (
          <>
            <H4 kind="light" bottomLine={props.title && props.title.length > 0}>
              {props.title}
            </H4>
          </>
        )}
      </Col>
    </Row>
  )
}
